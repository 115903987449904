// Define your branding configurations for different domains here
const brands: Record<string, BrandConfig> = {
  "ohana.mauiresortrentals.com": {
    portalName: "ohana",
    timezone: "Pacific/Honolulu",
    title: "Maui Resort Rentals",
    companyName: "Maui Resort Rentals, Inc.",
    description: "",
    greeting: "Aloha",
    brandId: "49017bd8-7ce6-40bd-952e-0b4a1429b19b",
    code: "MRR",
    appleIconUrl: "/mrr/apple-touch-icon.png",
    logoUrl: "/mrr/logo-full-white.svg",
    iconUrl: "/mrr/geo-icon-white.svg",
    loadingImageUrl: "/mrr/geo-icon.svg",
    favIconUrl: "/mrr/favicon.ico",
    shortcutIconUrl: "/mrr/favicon-32x32.png",
    customCssUrl: "/mrr/style.css",
    domain: "mauiresortrentals.com",
    email: "devops@mauiresortrentals.com",
    locale: "Maui",
    address: {
      streetAddress: "10 Halawai Dr #A-3",
      city: "Lahaina",
      state: "HI",
      postalCode: "96761",
    },
    themeSettings: {
      themeColorPresets: "default",
    },
    phone: "+18086887538",
    phoneDisplay: "(808) 688-7538",
    phoneTollFree: "+18558086284",
    phoneTollFreeDisplay: "(855) 808-MAUI (6284)",
    sendGrid: {
      clientUrl: "https://mauiresortrentals.com",
      fromEmail: "Maui Resort Rentals <noreply@mauiresortrentals.com>",
      templateIds: {
        otp: "d-dc7a77974dd14944934f0d648322c386",
      },
    },
    termsAndConditions:
      "https://www.mauiresortrentals.com/terms-and-conditions",
    privacyPolicy: "https://www.mauiresortrentals.com/privacy-policy",
  },
  "ohana.beachviewrentals.com": {
    portalName: "ohana",
    timezone: "America/Los_Angeles",
    title: "Beachview Rentals",
    companyName: "Dream Resort Rentals, Inc.",
    description: "",
    greeting: "Welcome",
    brandId: "55d26c7f-eb8d-4f82-937b-8b4c3ca7c709",
    code: "BVR",
    appleIconUrl: "/bvr/apple-touch-icon.png",
    logoUrl: "/bvr/logo-full-white.svg",
    iconUrl: "/bvr/geo-icon-white.svg",
    loadingImageUrl: "/bvr/geo-icon.svg",
    favIconUrl: "/bvr/favicon.ico",
    shortcutIconUrl: "/bvr/favicon-32x32.png",
    customCssUrl: "/bvr/style.css",
    domain: "beachviewrentals.com",
    email: "devops@beachviewrentals.com",
    locale: "Newport Beach",
    address: {
      streetAddress: "314 Old Newport Blvd",
      city: "Newport Beach",
      state: "CA",
      postalCode: "92663",
    },
    themeSettings: {
      themeColorPresets: "default",
    },
    phone: "+19496732627",
    phoneDisplay: "(949) 673-2627",
    phoneTollFree: "TOLL FREE PHONE",
    phoneTollFreeDisplay: "TOLL FREE PHONE DISPLAY",
    sendGrid: {
      clientUrl: "https://beachviewrentals.com",
      fromEmail: "Beachview Rentals <noreply@beachviewrentals.com>",
      templateIds: {
        otp: "d-5eb676878be44ddea65c527cb999a4e7",
      },
    },
    termsAndConditions:
      "https://beachviewrealty.com/additional-information/terms-and-conditions",
    privacyPolicy:
      "https://beachviewrealty.com/additional-information/privacy-policy",
  },
  default: {
    portalName: "ops",
    title: "Dream Resorts",
    timezone: "UTC",
    companyName: "Dream Resorts, Inc.",
    description: "",
    greeting: "Hello",
    brandId: "abbfaa7b-89c6-4665-804c-55c47219ed1c",
    code: "DRR",
    appleIconUrl: "/drr/apple-touch-icon.png",
    logoUrl: "/drr/logo.png",
    iconUrl: "/mrr/logo.svg",
    favIconUrl: "/drr/favicon.ico",
    loadingImageUrl: "/mrr/geo-icon.svg",
    shortcutIconUrl: "/drr/favicon-16x16.png",
    customCssUrl: "/drr/style.css",
    domain: "dreamresorts.com",
    email: "support@dreamresorts.com",
    locale: "Anywhere",
    address: {
      streetAddress: "123 Anytown",
      city: "Lahaina",
      state: "HI",
      postalCode: "96761",
    },
    themeSettings: {
      themeColorPresets: "default",
    },
    phone: "+18086887538",
    phoneDisplay: "(808) 688-7538",
    phoneTollFree: "+18558086284",
    phoneTollFreeDisplay: "(855) DREAM-BIG",
    sendGrid: {
      clientUrl: "https://dreamresorts.com",
      fromEmail: "Dream Resorts <noreply@dreamresorts.com>",
      templateIds: {
        otp: "d-dc7a77974dd14944934f0d648322c386",
      },
    },
    termsAndConditions: "",
    privacyPolicy: "",
  },
};
const host = process.env.REACT_APP_HOSTNAME || window.location.hostname;
const brand = brands[host] || brands.default;

export default brand;
